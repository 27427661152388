import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import WhiteArrow from "../assets/images/button-arrow.png";
import Tick from "../assets/images/price-tick.png";
import Tick2 from "../assets/images/price-tick2.png";
import Footer from "../Components/Footer";
import InfoBanner from "../Components/InfoBanner";
import DownloadModal from "../Components/modal/DownloadModal";
import NavBar from "../Components/NavBar";
import IntroTitle from "../Components/shared/IntroTitle";

const monthlyPricingData = [
  {
    feature: "Unlimited Access to Lesson Video",
  },
  {
    feature: "Unlimited Access to Lesson Notes",
  },
  {
    feature: "Access to Live Classes",
  },
  {
    feature:
      " Over 40k Examination Preparation Questions and Solutions: WAEC, JAMB, BECE, IGSCE, JUPEB & POST UTME",
  },
  {
    feature: "Offline learning",
  },
  {
    feature: "Ask a Tutor",
  },
];

const termlyPricingData = [
  {
    feature: "Unlimited Access to Lesson Video",
  },
  {
    feature: "Unlimited Access to Lesson Notes",
  },
  {
    feature: "Access to Live Classes",
  },
  {
    feature:
      " Over 40k Examination Preparation Questions and Solutions: WAEC, JAMB, BECE, IGSCE, JUPEB & POST UTME",
  },
  {
    feature: "Offline learning",
  },
  {
    feature: "Ask a Tutor",
  },
];

const yearlyPricingData = [
  {
    feature: "Unlimited Access to Lesson Video",
  },
  {
    feature: "Unlimited Access to Lesson Notes",
  },
  {
    feature: "Access to Live Classes",
  },
  {
    feature:
      " Over 40k Examination Preparation Questions and Solutions: WAEC, JAMB, BECE, IGSCE, JUPEB & POST UTME",
  },
  {
    feature: "Offline learning",
  },
  {
    feature: "Ask a Tutor",
  },
];

const Pricing = () => {
  const [downloadLink, setDownloadLink] = useState("");
  const [openDownloadModal, setOpenDownloadModal] = useState(false);

  useEffect(() => {
    if (window.navigator.userAgent.match(/Android/i)) {
      // Device is an Android
      setDownloadLink(
        "https://play.google.com/store/apps/details?id=com.A1Tutor.a1_app"
      );
    } else if (window.navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      // Device is an iPhone, iPad, or iPod
      setDownloadLink("https://apps.apple.com/ng/app/a1tutor/id1595970443");
    } else if (window.navigator.userAgent.match(/IEMobile/i)) {
      // Device is a Windows Phone
      setDownloadLink(
        "https://play.google.com/store/apps/details?id=com.A1Tutor.a1_app"
      );
    } else {
      // Device is a desktop or laptop
      setDownloadLink(
        "https://play.google.com/store/apps/details?id=com.A1Tutor.a1_app"
      );
    }
  }, []);

  return (
    <>
      <main className="container-fluid px-0">
        <InfoBanner />

        <NavBar setOpenDownloadModal={setOpenDownloadModal} />
        <header className="container-fluid pricing-header">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-10 mx-auto pricing-header-content">
                <div className="col-lg-11 mx-auto">
                  <IntroTitle>
                    Don’t worry! The A1Tutor app is a FREE learning App
                  </IntroTitle>
                </div>

                <div>
                  <a href={downloadLink} style={{ textDecoration: "none" }}>
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      className="white-btn justify-content-center d-flex align-items-center mx-auto"
                      style={{ gap: "5px" }}
                    >
                      <span>Download App</span>
                      <span>
                        <img
                          src={WhiteArrow}
                          alt=""
                          style={{ width: "14px" }}
                        />
                      </span>
                    </motion.button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </header>
{/*         <section className="container-fluid py-5 invest">
          <div className="container">
            <div className="row g-0">
              <div className="col-lg-6 mx-auto text-center">
                <div>
                  <div className="invest-header mb-5">
                    <h3
                      className="tx-blue aos-init aos-animate"
                      data-aos="fade-up"
                    >
                      Invest in yourself
                    </h3>
                    <p className="aos-init aos-animate" data-aos="fade-up">
                      Hey champ! kindly select your preferred subscription rate.
                      Don’t worry we won’t charge you a million dollars.
                    </p>
                  </div>
                </div>
              </div>

              <div className="invest-content row gy-3 mx-auto px-0">
                <div className="col-lg-4" data-aos="zoom-in">
                  <div className="box one mb-4 px-3 py-4 p-sm-5 px-lg-3 py-lg-4 p-xl-4 p-xxl-5 h-100">
                    <div className="price-header">
                      <h5>Monthly</h5>
                      <div className="text-center">
                        <h4>
                          &#8358; 4,999<span className="month"> /month</span>
                        </h4>

                        <span style={{ fontSize: "16px" }}>or</span>
                        <h4>
                          &#36;12 <span className="month">/month</span>
                        </h4>
                      </div>
                    </div>
                    <div className="text-center">
                      <Link to="" style={{ textDecoration: "none" }}>
                        <button
                          whileHover={{ scale: 1.02 }}
                          whileTap={{ scale: 0.98 }}
                          className="white-btn justify-content-center d-flex align-items-center mx-auto"
                          style={{ gap: "5px" }}
                        >
                          <span>Get Started</span>
                          <span>
                            <img
                              src={WhiteArrow}
                              alt=""
                              style={{ width: "14px" }}
                            />
                          </span>
                        </button>
                      </Link>
                    </div>
                    <div className="price-body mt-5">
                      {monthlyPricingData.map((eachData, i) => (
                        <div key={i} className="py-4 price-body-item  d-flex">
                          <div>
                            <img src={Tick} alt="" />
                          </div>
                          <div>
                            <p>{eachData.feature}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="col-lg-4" data-aos="zoom-in">
                  <div className="box two mb-4 px-3 py-4 p-sm-5 px-lg-3 py-lg-4 p-xl-4 p-xxl-5 h-100">
                    <div className="price-header">
                      <h5>Termly</h5>
                      <div className="text-center mt-3">
                        <h6 className="slashed-price">&#8358; 14,999 </h6>
                        <h4>
                          &#8358; 9,999<span className="month"> /3 months</span>
                        </h4>

                        <span style={{ fontSize: "16px" }}>or</span>
                        <h4>
                          &#36;25 <span className="month">/3 months</span>
                        </h4>
                      </div>
                    </div>
                    <div className="text-center py-1">
                      <Link to="" style={{ textDecoration: "none" }}>
                        <button
                          whileHover={{ scale: 1.02 }}
                          whileTap={{ scale: 0.98 }}
                          className="white-btn justify-content-center d-flex align-items-center mx-auto"
                          style={{ gap: "5px" }}
                        >
                          <span>Get Started</span>
                          <span>
                            <img
                              src={WhiteArrow}
                              alt=""
                              style={{ width: "14px" }}
                            />
                          </span>
                        </button>
                      </Link>
                    </div>
                    <div className="price-body mt-5">
                      {termlyPricingData.map((eachData, i) => (
                        <div key={i} className="py-4 price-body-item  d-flex">
                          <div>
                            <img src={Tick} alt="" />
                          </div>
                          <div>
                            <p>{eachData.feature}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="col-lg-4" data-aos="zoom-in">
                  <div className="box three mb-4 px-3 py-4 p-sm-5 px-lg-3 py-lg-4 p-xl-4 p-xxl-5 h-100">
                    <div className="price-header">
                      <h5>Yearly</h5>
                      <div className="text-center">
                        <h6 className="slashed-price">&#8358; 59,999 </h6>
                        <h4>
                          &#8358; 29,999
                          <span className="month"> /12 months</span>
                        </h4>

                        <span style={{ fontSize: "16px" }}>or</span>
                        <h4>
                          &#36;65 <span className="month">/12 months</span>
                        </h4>
                      </div>
                    </div>
                    <div className="text-center">
                      <Link to="" style={{ textDecoration: "none" }}>
                        <button
                          whileHover={{ scale: 1.02 }}
                          whileTap={{ scale: 0.98 }}
                          className="white-btn justify-content-center d-flex align-items-center mx-auto"
                          style={{ gap: "5px" }}
                        >
                          <span>Get Started</span>
                          <span>
                            <img
                              src={WhiteArrow}
                              alt=""
                              style={{ width: "14px" }}
                            />
                          </span>
                        </button>
                      </Link>
                    </div>
                    <div className="price-body mt-5">
                      {yearlyPricingData.map((eachData, i) => (
                        <div key={i} className="py-4 price-body-item  d-flex">
                          <div>
                            <img src={Tick2} alt="" />
                          </div>
                          <div>
                            <p>{eachData.feature}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <Footer />
      </main>
      {openDownloadModal && (
        <DownloadModal setOpenDownloadModal={setOpenDownloadModal} />
      )}
    </>
  );
};

export default Pricing;
